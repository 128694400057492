import React from 'react';
import YouTube from 'react-youtube';
import { createCookie, readCookie } from '../../Utils/Cookies';

interface YouTubeProps {
  videoId: string;
  opts?: object;
  onReady?: (event: any) => void;
  onError?: (event: any) => void;
  onPlay?: (event: any) => void;
  onPause?: (event: any) => void;
  onEnd?: (event: any) => void;
}

export const YouTubeCookie: React.FC<YouTubeProps> = ({
  videoId,
  opts = {},
  onReady,
  onError,
  onPlay,
  onPause,
  onEnd
}) => {
  const handleReady = (event: any) => {
    if (onReady) onReady(event);
    const savedTime = readCookie('ply_time');
    if (savedTime) {
      event.target.seekTo(parseFloat(savedTime));
    }
  };

  const handleStateChange = (event: any) => {
    if (event.data === YouTube.PlayerState.PLAYING) {
      if (onPlay) onPlay(event);
    } else if (event.data === YouTube.PlayerState.PAUSED) {
      if (onPause) onPause(event);
    } else if (event.data === YouTube.PlayerState.ENDED) {
      if (onEnd) onEnd(event);
    }
    createCookie('ply_time', event.target.getCurrentTime().toString(), 1);
  };

  const handleError = (event: any) => {
    if (onError) onError(event);
  };

  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      onReady={handleReady}
      onStateChange={handleStateChange}
      onError={handleError}
    />
  );
};
