import { useState } from "react";
import "./css/AlphabeticPagination.css";

interface AlphabeticPaginationProps {
    onOptionSelect: (option: string) => void;
}

export const AlphabeticPagination: React.FC<AlphabeticPaginationProps> = ({onOptionSelect}) => {
  const paginationOptions = [
    "Default",
    "#",
    ...Array.from(Array(26)).map((_, i) => String.fromCharCode(65 + i)),
  ];
  const [selectedOption, setSelectedOption] = useState(() => {
    return localStorage.getItem("selectedLetter") || "Default";
  });

  const handleClick = (option: string) => {
    setSelectedOption(option);
    onOptionSelect(option);
  };

  return (
    <nav aria-label='....'>
      <ul className='alphabetic-pagination'>
        {paginationOptions.map((option) => (
          <button 
            key={option} className={`alphabetic-pagination-button ${selectedOption === option ? "active" : ""}`}
            onClick={() => handleClick(option)}>
            {option}
          </button>
        ))}
      </ul>
    </nav>
    
  );
};
