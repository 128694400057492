import { useEffect, useState } from "react";
import { MovieCard } from "./components/MovieCard";
import MovieModel from "../../models/MovieModel";
import MovieEpisodeModel from "../../models/MovieEpisodeModel";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { Pagination } from "../Utils/Pagination";
import { Link } from "react-router-dom";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import { AlphabeticPagination } from "../Utils/AlphabeticPagination";

export const MoviesPage = () => {
  const [movies, setMovies] = useState<MovieModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  const [searchValue, setSearchValue] = useState<string>(() => {
    return localStorage.getItem("selectedLetter") || "";
  });

  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(localStorage.getItem("currentMoviesPage") || "1")
  );
  const [moviesPerPage, setMoviesPerPage] = useState(35);
  const [totalPages, setTotalPages] = useState(0);
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    localStorage.setItem("currentMoviesPage", pageNumber.toString());
  };

  useEffect(() => {
    const fetchMovies = async () => {
      let url: string;

      if (searchValue.match(/^[a-zA-Z]/)) {
        url = `${
          process.env.REACT_APP_API_URI
        }/api/movies/search/starting-with-letter?letter=${searchValue}&page=${
          currentPage - 1
        }&size=${moviesPerPage}`;
      } else if (searchValue.match("#")) {
        url = `${
          process.env.REACT_APP_API_URI
        }/api/movies/search/starting-with-number?letter=${searchValue}&page=${
          currentPage - 1
        }&size=${moviesPerPage}`;
      } else {
        url = `${process.env.REACT_APP_API_URI}/api/movies?page=${
          currentPage - 1
        }&size=${moviesPerPage}`;
      }

      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Something went wrong!");
        }

        const responseJson = await response.json();
        const responseData = responseJson.content;

        setTotalPages(responseJson.totalPages);

        const loadedMovies: MovieModel[] = responseData.map(
          (movieData: any) => {
            const loadedEpisodes: MovieEpisodeModel[] = movieData.episodes
              .map(
                (episodeData: any) =>
                  new MovieEpisodeModel(
                    episodeData.episode_id,
                    episodeData.episodeNumber,
                    episodeData.title.toUpperCase(),
                    episodeData.videoId,
                    episodeData.description
                  )
              )
              .sort(
                (a: MovieEpisodeModel, b: MovieEpisodeModel) =>
                  a.episode_number - b.episode_number
              );

            return new MovieModel(
              movieData.movie_id,
              movieData.title.toUpperCase(),
              movieData.uploadDate,
              movieData.releaseYear,
              movieData.genre,
              movieData.moviePoster,
              loadedEpisodes,
              movieData.uploadChannel,
              movieData.dateModified
            );
          }
        );

        setMovies(loadedMovies);
      } catch (error: any) {
        setHttpError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMovies();
    window.scroll(0, 0);
  }, [currentPage, moviesPerPage, searchValue]);

  useEffect(() => {
    const handleResize = () => {
      const isFourMoviesPerPage =
        window.innerWidth > 577 && window.innerWidth < 1440;
      const isThreeMoviesPerPage = window.innerWidth <= 576;

      let updatedMoviesPerPage = 35;

      if (isFourMoviesPerPage) {
        if (isTablet) {
          updatedMoviesPerPage = 30;
          // find a way to check for port or land port = 32 land = 30
        } else {
          updatedMoviesPerPage = 32;
        }
      } else if (isThreeMoviesPerPage) {
        updatedMoviesPerPage = 30;
      }

      setMoviesPerPage(updatedMoviesPerPage);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <SpinnerLoading />
      </div>
    );
  }

  if (httpError) {
    return (
      <div className='container m-5'>
        <p>{httpError}</p>
      </div>
    );
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const handleOptionSelect = (option: string) => {
    if (option === "Default") {
      setSearchValue("");
      localStorage.removeItem("selectedLetter");
    } else {
      setSearchValue(option);
      localStorage.setItem("selectedLetter", option);
    }

    setCurrentPage(1);
    localStorage.setItem("currentMoviesPage", "1");
  };

  return (
    <div>
      {/* Desktop */}
      {isDesktop && (
        <>
          <div className='d-none desktop d-xl-block'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='15rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none desktop d-lg-block d-xl-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='13rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none desktop d-md-block d-lg-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='10.5rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none desktop d-sm-block d-md-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='8.5rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='desktop d-sm-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='8rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-5 mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* Tablet*/}
      {isTablet && (
        <>
          <div className='d-none tablet d-lg-block d-xl-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='14rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none tablet d-md-block d-lg-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='11rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* Mobile*/}
      {isMobileOnly && (
        <>
          <div className='d-none mobile d-md-block d-lg-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='11rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none mobile d-sm-block d-md-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='10rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-auto mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='mobile d-sm-none'>
            <div className=''>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div
                className='row justify-content-start'
                style={{ background: "black" }}>
                <AlphabeticPagination onOptionSelect={handleOptionSelect}/>
              </div>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-5 mt-2 mx-2'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='11rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div className='mx-3 mt-3' style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
