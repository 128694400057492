import { BsTwitterX , BsYoutube} from "react-icons/bs";

export const Footer = () => {
  return (
    <div>
      <footer className='container d-flex flex-wrap justify-content-between align-items-center py-3 border-top bg-dark  mt-3'>
        <div className='col-md-4 d-flex align-items-center'>
          <span className='mb-3 mb-md-0 text-body-secondary'><p className="text-white">&copy; 2024 TalaRetro. All rights reserved.</p></span>
        </div>

        <ul className='nav col-md-4 justify-content-end list-unstyled d-flex'>
          <li className='ms-3'>
            <a className='text-body-secondary' href='https://twitter.com/talaretro'>
                <div className="d-flex">
                    <BsTwitterX color="white" fontSize="1.5em" className="mx-2"/>
                </div>
            </a>
          </li>
          <li className='ms-3'>
            <a className='text-body-secondary' href='https://www.youtube.com/@TalaRetro'>
                <div className="d-flex">
                    <BsYoutube color="white" fontSize="1.5em" className="mx-2" />
                </div>
            </a>
          </li>
          <li className='ms-3'>
            <a className='text-body-secondary' href="mailto:talaretro@outlook.com">
                <div className="d-flex">
                    <p className="text-white mx-2 ">Contact Us </p>
                </div>    
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};
