import React from 'react';
import { YouTubeCookie } from './YoutubeCookie';

// Function to simulate logging errors to an external service
const logError = (message: string) => {
  // Example function to send error logs to a logging service
  // console.error('Logging error:', message);
  // You can integrate with a service like Sentry, LogRocket, etc.
};

export const CookieYoutubePlayer: React.FC<{videoId: string, height: string, width: string}> = (props) => {
  const handleReady = (event: any) => {
    // console.log('Player is ready');
    // Optionally, you could update the UI to show that the player is ready
  };

  const handleError = (event: any) => {
    console.error('An error occurred:', event.data);
    logError(`YouTube player error: ${event.data}`);
    // Notify the user about the error
    alert('An error occurred while loading the video. Please try again later.');
  };

  const handlePlay = (event: any) => {
    // console.log('Video is playing');
    // Update UI or state to reflect that the video is playing
    // e.g., setPlayerState('playing');
  };

  const handlePause = (event: any) => {
    // console.log('Video is paused');
    // Update UI or state to reflect that the video is paused
    // e.g., setPlayerState('paused');
  };

  const handleEnd = (event: any) => {
    // console.log('Video has ended');
    // Update UI to reflect that the video has ended
    // e.g., setPlayerState('ended');
  };

  return (
    <div>
      <YouTubeCookie
        videoId={props.videoId} 
        opts={{
          height: `${props.height}`,
          width: `${props.width}`, 
          playerVars: {
            autoplay: 1, 
            rel: 0,
          },
        }}
        onReady={handleReady}
        onError={handleError}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnd={handleEnd}
      />
    </div>
  );
};
